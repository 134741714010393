import { Middleware } from '@nuxt/types';
import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { magentoUrlResolver, amplienceUrlResolver, isAlgoliaCategory } from '~/helpers/urlResolver';
import { RoutableInterface, UrlRewriteEntityTypeEnum } from '~/modules/GraphQL/types';

const urlResolverMiddleware: Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;

  Logger.debug('middleware/url-resolver', path);

  let results: RoutableInterface | null = await magentoUrlResolver(context, path);

  // If the results are found and the type is CATEGORY and the path is an Algolia category,
  // we change the type to ALGOLIA_CATEGORY
  if (results?.type === 'CATEGORY' && isAlgoliaCategory(context, path)) {
    results = {
      ...results,
      type: 'ALGOLIA_CATEGORY' as UrlRewriteEntityTypeEnum,
    };
  }

  // If the results are not found or the type is CMS_PAGE,
  // we try to resolve the URL using Amplience,
  if (!results || results.type === 'CMS_PAGE') {
    results = await amplienceUrlResolver(context, path);
  }

  // If the results are not found, we throw a 404 error
  if (!results) context.error({ statusCode: 404 });

  pageStore.$patch((state) => {
    state.routeData = results;
  });
};

export default urlResolverMiddleware;
