import type { BidsForProductInterface } from '@cellar-services/types';
import { useContext, ref } from '@nuxtjs/composition-api';

export const useBidsForProduct = () => {
  const loading = ref<boolean>(false);
  const error = ref<string>('');
  const data = ref<BidsForProductInterface>({
    total_cases: 0,
    listed_cases: 0,
    highest_bid: 0,
    lowest_listing_price: 0,
    last_bbx_transaction: 0,
    my_listing_price: 0,
    current_bids: []
  });
  const context = useContext();

  const getBidsForProduct = async (payload) => {
    loading.value = true;

    try {
      const response = await context.$vsf.$cellarServices.api.getBidsForProduct(payload);

      if (!response || response.status !== 200) {
        throw new Error('Unable to get received bids, please try again.');
      }

      data.value = response.data;
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    error,
    data,
    getBidsForProduct
  };
};
