import { customerBbxListingsQuery } from '@cellar-services/queries/getCustomerBbxListings.gql';
import { useApi, useUiNotification } from '~/composables';
import { useListingsStore } from '@cellar-services/stores/bbx';
import { storeToRefs } from 'pinia';
import { Logger } from '~/helpers/logger';
import { useContext } from '@nuxtjs/composition-api';
import { useQueryParams } from '~/bbrTheme/composables';
import { formatAttributes, formatAggregations } from '@cellar-services/composables/helpers';
import { ListingPayloadInterface, CustomerCellarResponse } from '@cellar-services/types';

/**
 * BBX listing actions
 */
export const useBBXListings = () => {
  const context = useContext();
  const { query } = useApi();
  const { setQueryParams } = useQueryParams();

  const bbxListingsStore = useListingsStore();
  const { graphQueryParams, pageSize, queryParams } = storeToRefs(bbxListingsStore);

  const { send: sendNotification } = useUiNotification();

  const {
    setItems,
    setIsLoading,
    setTotalItems,
    setTotalPages,
    setAggregations,
    setPageSize,
    setCurrentPage,
    setIsBlockingLoading,
    resetStore,
  } = bbxListingsStore;

  const createBBXListing = async (payload: ListingPayloadInterface): Promise<any> =>
    await context.$vsf.$cellarServices.api.createBBXListing(payload);

  const editBBXListing = async (payload): Promise<any> =>
    await context.$vsf.$cellarServices.api.editBBXListing(payload);

  const getBBXListings = async (): Promise<void> => {
    const items = {};

    try {
      setIsLoading(true);

      const response = await query<CustomerCellarResponse>(customerBbxListingsQuery, graphQueryParams.value);

      if (response.errors || !response.data) {
        setIsLoading(false);

        Logger.error(response.errors);

        return;
      }

      const data = response.data.customer.cellar_listed[0];

      if (!data) {
        resetStore();

        return;
      }

      const totalItems = data.total_count;
      const aggregations = formatAggregations(data.aggregations);
      const pageInfo = data.page_info;

      for (const item of data.items) {
        const attributes = formatAttributes(item.attributes_value);

        item.internal_id = item.item_id;

        items[item.internal_id] = {
          ...item,
          ...attributes,
        };
      }

      setItems(items);
      setTotalItems(totalItems);
      setTotalPages(pageInfo.total_pages);
      setCurrentPage(context, pageInfo.current_page);
      setPageSize(context, pageInfo.page_size);

      /**
       * Set aggregations (used for filters in the overview page) only when the query isn't filtered
       * as a filtered query returns aggregations only for the filtered subset items.
       */
      if (!('filter' in graphQueryParams.value)) {
        setAggregations(aggregations);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const removeBBXListings = async (payload) => {
    try {
      const response = await context.$vsf.$cellarServices.api.removeBBXListings(payload);
      const payloadItemsLength = payload.listing_ids?.length;
      let errorMessage = 'Unable to remove from BBX, please try again.';
      const successMessage = payloadItemsLength > 1 ?
        'You have successfully removed your listings from BBX.' :
        'You have successfully removed your listing from BBX.';
      const hasErroredItems = Boolean(response?.data &&
        Object.values(response.data).some((item: any) => item.http_status_code === 404));

      const handleErrorMessage = (isPlural) => {
        sendNotification({
          icon: 'error',
          id: Symbol('listings_remove_error'),
          message: context.i18n.t(
            errorMessage
          ) as string,
          persist: true,
          title: context.i18n.t(
            isPlural ? 'Error when removing listings' : 'Error when removing listing'
          ) as string,
          type: 'danger',
        });
      }

      if (!response || response.status !== 200) {
        if(response.status === 401) {
          errorMessage = response.data;
        }

        handleErrorMessage(payloadItemsLength > 1);
        throw new Error(errorMessage);
      } else {
        if (hasErroredItems) {
          handleErrorMessage(Object.values(response.data).filter((item: any) => {
            return item.http_status_code === 404
          }).length > 1);
        }

        setIsBlockingLoading(true);

        sendNotification({
          icon: 'success',
          id: Symbol('listings_remove_success'),
          message: context.i18n.t(
            successMessage
          ) as string,
          persist: true,
          title: context.i18n.t(
            payloadItemsLength > 1 ? 'Listings removed' : 'Listing removed'
          ) as string,
          type: 'success',
        });

        return await getBBXListings();
      }
    } catch (err) {
      Logger.error('removeBBXListings', err);
    } finally {
      setIsBlockingLoading(false);
    }
  }

  const setPage = async (newPage: number, newSize: number) => {
    // Blocks UI while loading
    setIsBlockingLoading(true);

    // Reset page to 1 if page size was changed
    setCurrentPage(context, newSize !== pageSize.value ? 1 : newPage);
    setPageSize(context, newSize);

    await getBBXListings();

    // Set URL params with latest query params
    setQueryParams(queryParams.value);

    setIsBlockingLoading(false);
  };

  return {
    createBBXListing,
    editBBXListing,
    getBBXListings,
    removeBBXListings,
    setPage,
  };
};
