



















































import {
  defineComponent,
  PropType,
  toRef,
  ref,
  watch,
  onBeforeUnmount
} from '@nuxtjs/composition-api';
import TouchTarget from '~/bbrTheme/components/atoms/TouchTarget.vue';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';

/**
 * This is a fully accessible modal component
 *
 * ref: https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/
 */
export default defineComponent({
  name: 'Modal',
  components: {
    TouchTarget,
  },
  directives: {
    focusTrap,
  },
  props: {
    isUseVShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
    labelId: {
      type: String,
      default: '',
    },
    mode: {
      type: String as PropType<'sidebar' | 'fullscreen'>,
      default: 'sidebar',
    },
    backLabel: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const modal = ref<HTMLElement | null>(null);
    const isOpen = toRef(props, 'isOpen');

    watch(isOpen, (value: boolean) => document.body.classList.toggle('no-scroll', value));

    onBeforeUnmount(() => {
      document.body.classList.remove('no-scroll');
    });

    return {
      modal,
    };
  },
});
