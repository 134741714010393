import {
  fragmentCellarItemOwnership
} from './fragments';

export const customerBidsReceivedQuery = `
  query CustomerCellarBids($currentPage: Int = 1, $pageSize: Int = 24, $filter: ProductAttributeFilterInput) {
    customer {
      cellar_bid(
        accountData: "default",
        currentPage: $currentPage,
        pageSize: $pageSize,
        filter: $filter
      ) {
        is_default
        sap_unique_id
        items {
          uid
          item_id
          highest_bid_id
          url_key
          name
          sku
          parent_sku
          cases_qty
          bottles_qty
          stored_status
          change_value
          change_value_percentage
          highest_bid
          is_bbx_allowed
          listing_id
          listing_price
          listing_qty
          market_price
          purchase_price
          batch
          ...CellarItemOwnershipFragment
          description {
            html
          }
          url_key
          attributes_value(
            attribute_codes: [
              "main_image"
              "family_type"
              "colour"
              "maturity"
              "case_order_unit"
              "bottle_volume"
              "drinking_from_year"
              "drinking_to_year"
              "alcohol"
              "style_temp"
              "sweetness"
              "body"
              "grape_list"
            ]
          ) {
            code
            value
            label
            position
            sort_order
          }
          reviews {
            items {
              nickname
              score
            }
          }
        }
        aggregations(filter: {}) {
          attribute_code
          count
          label
          options {
            count
            label
            value
          }
          position
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        sort_fields {
          default
          options {
            label
            value
          }
        }
        total_count
      }
    }
  }
  ${fragmentCellarItemOwnership}
`;
