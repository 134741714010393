import { customerCellarQuery } from '@cellar-services/queries/getCustomerCellar.gql';
import { useApi } from '~/composables';
import { useCellarStore } from '@cellar-services/stores/cellar';
import { useBidsReceivedStore } from '@cellar-services/stores/bbx/bids-received';
import { useListingsStore } from '@cellar-services/stores/bbx/listings';
import { storeToRefs } from 'pinia';
import { useContext } from '@nuxtjs/composition-api';
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useQueryParams } from '~/bbrTheme/composables';
import { formatAttributes, formatAggregations } from '@cellar-services/composables/helpers';
import type { CustomerCellarResponse, CprStockInterface } from '@cellar-services/types';
import { isDualRunning } from '~/bbrTheme/helpers/dualRunning';
import { useBBXBidsReceived, useBBXBidsPlaced, useBBXListings } from '@cellar-services/composables/useBBX';

const ACCOUNT_PAGE = 'customer-my-profile';

/**
 * Cellar actions
 */
export const useCellar = () => {
  const context = useContext();
  const route = useRoute();
  const router = useRouter();
  const { query } = useApi();
  const { setQueryParams } = useQueryParams();

  const cellarStore = useCellarStore();
  const { graphQueryParams, isEmpty, pageSize, queryParams, defaultID } = storeToRefs(cellarStore);
  const {
    setItems,
    setIsLoading,
    setIsBlockingLoading,
    setTotalItems,
    setTotalPages,
    setAggregations,
    setStats,
    resetActiveRows,
    setPageSize,
    setCurrentPage,
    setFilter,
    resetStore,
  } = cellarStore;

  const bidsReceivedStore = useBidsReceivedStore();
  const listingsStore = useListingsStore();

  const { getBidsReceived } = useBBXBidsReceived();
  const { getBidsPlaced } = useBBXBidsPlaced();
  const { getBBXListings } = useBBXListings();

  const getCellarItems = async (): Promise<void> => {
    const items = {};

    try {
      setIsLoading(true);

      const response = await query<CustomerCellarResponse>(customerCellarQuery, graphQueryParams.value);

      if (response.errors || !response.data) {
        setIsLoading(false);

        Logger.error(response.errors);

        return;
      }

      const data = response.data.customer.cellar[0];

      if (!data) {
        resetStore();

        return;
      }

      const totalItems = data.total_count;
      const aggregations = formatAggregations(data.aggregations);
      const sortFields = data.sort_fields;
      const pageInfo = data.page_info;

      for (const item of data.items) {
        const attributes = formatAttributes(item.attributes_value);

        item.internal_id = item.item_id;

        items[item.internal_id] = {
          ...item,
          ...attributes,
        };
      }

      setItems(items);
      setTotalItems(totalItems);
      setTotalPages(pageInfo.total_pages);
      setCurrentPage(context, pageInfo.current_page);
      setPageSize(context, pageInfo.page_size);

      /**
       * Set aggregations (used for filters in the overview page) only when the query isn't filtered
       * as a filtered query returns aggregations only for the filtered subset items.
       */
      if (!('filter' in graphQueryParams.value)) {
        setAggregations(aggregations);
      }
    } catch (err) {
      Logger.error(err);
    } finally {
      setIsLoading(false);

      if (isDualRunning(context.app.$cookies)) {
        return;
      }

      // Redirect to my account page if user has no items
      if (isEmpty.value && route.value.path.startsWith('/cellar')) {
        Logger.info('Redirecting to My account as cellar is empty.');

        if (process.server) {
          context.redirect(context.localePath(ACCOUNT_PAGE));
        } else {
          router.push(context.localeRoute(ACCOUNT_PAGE));
        }
      }
    }
  };

  const getCprStock = async (payload): Promise<CprStockInterface[]> => {
    return await context.$vsf.$cellarServices.api.getCprStock(payload);
  }

  const getBBXData = async (): Promise<void> => {
    getBidsPlaced();
    getBidsReceived();
    getBBXListings();
  }

  const getCellarSummary = async (): Promise<void> => {
    const data = await context.$vsf.$cellarServices.api.getCellarSummary({ account_id: defaultID.value });

    setStats(data);
  };

  const refreshCellar = async (): Promise<any> => {
    Logger.info('Refreshing cellar...');

    return Promise.allSettled([getCellarItems(), getCellarSummary(), getBBXData()]);
  };

  const setPage = async (newPage: number, newSize: number) => {
    // Blocks UI while loading
    setIsBlockingLoading(true);

    // Reset page to 1 if page size was changed
    setCurrentPage(context, newSize !== pageSize.value ? 1 : newPage);
    setPageSize(context, newSize);

    await getCellarItems();

    resetActiveRows();

    // Set URL params with latest query params
    setQueryParams(queryParams.value);

    setIsBlockingLoading(false);
  };

  // TODO: Handle multiple filters
  const filterCellar = async (filter) => {
    setFilter(filter);

    return await getCellarItems();
  };

  const updateCellarItem = (itemID, item) => {
    cellarStore.setItem(itemID, item);
    bidsReceivedStore.setItem(itemID, item);
    listingsStore.setItem(itemID, item);
  };

  return {
    refreshCellar,
    getCellarItems,
    getCprStock,
    getCellarSummary,
    setPage,
    filterCellar,
    updateCellarItem,
  };
};
