export const Accordion = () => import('../../modules/amplience/components/content-type/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AmplienceFooter = () => import('../../modules/amplience/components/content-type/AmplienceFooter.vue' /* webpackChunkName: "components/amplience-footer" */).then(c => wrapFunctional(c.default || c))
export const AmplienceHeader = () => import('../../modules/amplience/components/content-type/AmplienceHeader.vue' /* webpackChunkName: "components/amplience-header" */).then(c => wrapFunctional(c.default || c))
export const AnchorButton = () => import('../../modules/amplience/components/content-type/AnchorButton.vue' /* webpackChunkName: "components/anchor-button" */).then(c => wrapFunctional(c.default || c))
export const Article = () => import('../../modules/amplience/components/content-type/Article.vue' /* webpackChunkName: "components/article" */).then(c => wrapFunctional(c.default || c))
export const ArticleCardList = () => import('../../modules/amplience/components/content-type/ArticleCardList.vue' /* webpackChunkName: "components/article-card-list" */).then(c => wrapFunctional(c.default || c))
export const AudioPodcast = () => import('../../modules/amplience/components/content-type/AudioPodcast.vue' /* webpackChunkName: "components/audio-podcast" */).then(c => wrapFunctional(c.default || c))
export const Author = () => import('../../modules/amplience/components/content-type/Author.vue' /* webpackChunkName: "components/author" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../modules/amplience/components/content-type/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BodyImage = () => import('../../modules/amplience/components/content-type/BodyImage.vue' /* webpackChunkName: "components/body-image" */).then(c => wrapFunctional(c.default || c))
export const CategoryHeroBanner = () => import('../../modules/amplience/components/content-type/CategoryHeroBanner.vue' /* webpackChunkName: "components/category-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const ContactEnquiries = () => import('../../modules/amplience/components/content-type/ContactEnquiries.vue' /* webpackChunkName: "components/contact-enquiries" */).then(c => wrapFunctional(c.default || c))
export const ContactInfo = () => import('../../modules/amplience/components/content-type/ContactInfo.vue' /* webpackChunkName: "components/contact-info" */).then(c => wrapFunctional(c.default || c))
export const ContactPoints = () => import('../../modules/amplience/components/content-type/ContactPoints.vue' /* webpackChunkName: "components/contact-points" */).then(c => wrapFunctional(c.default || c))
export const CoreValues = () => import('../../modules/amplience/components/content-type/CoreValues.vue' /* webpackChunkName: "components/core-values" */).then(c => wrapFunctional(c.default || c))
export const EmailSignUp = () => import('../../modules/amplience/components/content-type/EmailSignUp.vue' /* webpackChunkName: "components/email-sign-up" */).then(c => wrapFunctional(c.default || c))
export const Error404 = () => import('../../modules/amplience/components/content-type/Error404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c))
export const FeatureBlock = () => import('../../modules/amplience/components/content-type/FeatureBlock.vue' /* webpackChunkName: "components/feature-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureBlockAlt = () => import('../../modules/amplience/components/content-type/FeatureBlockAlt.vue' /* webpackChunkName: "components/feature-block-alt" */).then(c => wrapFunctional(c.default || c))
export const GlobalFooter = () => import('../../modules/amplience/components/content-type/GlobalFooter.vue' /* webpackChunkName: "components/global-footer" */).then(c => wrapFunctional(c.default || c))
export const GlobalHeader = () => import('../../modules/amplience/components/content-type/GlobalHeader.vue' /* webpackChunkName: "components/global-header" */).then(c => wrapFunctional(c.default || c))
export const GlobalHeaderNavigation = () => import('../../modules/amplience/components/content-type/GlobalHeaderNavigation.vue' /* webpackChunkName: "components/global-header-navigation" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../modules/amplience/components/content-type/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeadingWithCta = () => import('../../modules/amplience/components/content-type/HeadingWithCta.vue' /* webpackChunkName: "components/heading-with-cta" */).then(c => wrapFunctional(c.default || c))
export const HeroBanner = () => import('../../modules/amplience/components/content-type/HeroBanner.vue' /* webpackChunkName: "components/hero-banner" */).then(c => wrapFunctional(c.default || c))
export const ImageHalfWidth = () => import('../../modules/amplience/components/content-type/ImageHalfWidth.vue' /* webpackChunkName: "components/image-half-width" */).then(c => wrapFunctional(c.default || c))
export const InfoBlock = () => import('../../modules/amplience/components/content-type/InfoBlock.vue' /* webpackChunkName: "components/info-block" */).then(c => wrapFunctional(c.default || c))
export const JumboHeroBanner = () => import('../../modules/amplience/components/content-type/JumboHeroBanner.vue' /* webpackChunkName: "components/jumbo-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const MigrationBodyImage = () => import('../../modules/amplience/components/content-type/MigrationBodyImage.vue' /* webpackChunkName: "components/migration-body-image" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../modules/amplience/components/content-type/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const ProductBody = () => import('../../modules/amplience/components/content-type/ProductBody.vue' /* webpackChunkName: "components/product-body" */).then(c => wrapFunctional(c.default || c))
export const ProductBodyAfterContent = () => import('../../modules/amplience/components/content-type/ProductBodyAfterContent.vue' /* webpackChunkName: "components/product-body-after-content" */).then(c => wrapFunctional(c.default || c))
export const ProductBodySpirit = () => import('../../modules/amplience/components/content-type/ProductBodySpirit.vue' /* webpackChunkName: "components/product-body-spirit" */).then(c => wrapFunctional(c.default || c))
export const ProductLister = () => import('../../modules/amplience/components/content-type/ProductLister.vue' /* webpackChunkName: "components/product-lister" */).then(c => wrapFunctional(c.default || c))
export const PromoBar = () => import('../../modules/amplience/components/content-type/PromoBar.vue' /* webpackChunkName: "components/promo-bar" */).then(c => wrapFunctional(c.default || c))
export const Quote = () => import('../../modules/amplience/components/content-type/Quote.vue' /* webpackChunkName: "components/quote" */).then(c => wrapFunctional(c.default || c))
export const Recipe = () => import('../../modules/amplience/components/content-type/Recipe.vue' /* webpackChunkName: "components/recipe" */).then(c => wrapFunctional(c.default || c))
export const RegisterInterest = () => import('../../modules/amplience/components/content-type/RegisterInterest.vue' /* webpackChunkName: "components/register-interest" */).then(c => wrapFunctional(c.default || c))
export const RelatedProductsPlaceholder = () => import('../../modules/amplience/components/content-type/RelatedProductsPlaceholder.vue' /* webpackChunkName: "components/related-products-placeholder" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../modules/amplience/components/content-type/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const Shops = () => import('../../modules/amplience/components/content-type/Shops.vue' /* webpackChunkName: "components/shops" */).then(c => wrapFunctional(c.default || c))
export const ShopsFeatureBlock = () => import('../../modules/amplience/components/content-type/ShopsFeatureBlock.vue' /* webpackChunkName: "components/shops-feature-block" */).then(c => wrapFunctional(c.default || c))
export const SimpleFooter = () => import('../../modules/amplience/components/content-type/SimpleFooter.vue' /* webpackChunkName: "components/simple-footer" */).then(c => wrapFunctional(c.default || c))
export const SimpleHeader = () => import('../../modules/amplience/components/content-type/SimpleHeader.vue' /* webpackChunkName: "components/simple-header" */).then(c => wrapFunctional(c.default || c))
export const SocialIcons = () => import('../../modules/amplience/components/content-type/SocialIcons.vue' /* webpackChunkName: "components/social-icons" */).then(c => wrapFunctional(c.default || c))
export const SocialNetworks = () => import('../../modules/amplience/components/content-type/SocialNetworks.vue' /* webpackChunkName: "components/social-networks" */).then(c => wrapFunctional(c.default || c))
export const SocialSharing = () => import('../../modules/amplience/components/content-type/SocialSharing.vue' /* webpackChunkName: "components/social-sharing" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../modules/amplience/components/content-type/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../modules/amplience/components/content-type/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const UspInfo = () => import('../../modules/amplience/components/content-type/UspInfo.vue' /* webpackChunkName: "components/usp-info" */).then(c => wrapFunctional(c.default || c))
export const VideoAmplience = () => import('../../modules/amplience/components/content-type/VideoAmplience.vue' /* webpackChunkName: "components/video-amplience" */).then(c => wrapFunctional(c.default || c))
export const VideoYoutube = () => import('../../modules/amplience/components/content-type/VideoYoutube.vue' /* webpackChunkName: "components/video-youtube" */).then(c => wrapFunctional(c.default || c))
export const VintageChartsPlaceholder = () => import('../../modules/amplience/components/content-type/VintageChartsPlaceholder.vue' /* webpackChunkName: "components/vintage-charts-placeholder" */).then(c => wrapFunctional(c.default || c))
export const RenderContent = () => import('../../modules/amplience/components/render/Content.vue' /* webpackChunkName: "components/render-content" */).then(c => wrapFunctional(c.default || c))
export const RenderMarkdown = () => import('../../modules/amplience/components/render/Markdown.vue' /* webpackChunkName: "components/render-markdown" */).then(c => wrapFunctional(c.default || c))
export const RenderPOI = () => import('../../modules/amplience/components/render/POI.vue' /* webpackChunkName: "components/render-p-o-i" */).then(c => wrapFunctional(c.default || c))
export const RenderRecipe = () => import('../../modules/amplience/components/render/Recipe.vue' /* webpackChunkName: "components/render-recipe" */).then(c => wrapFunctional(c.default || c))
export const RenderRichText = () => import('../../modules/amplience/components/render/RichText.vue' /* webpackChunkName: "components/render-rich-text" */).then(c => wrapFunctional(c.default || c))
export const OrganismArticleCardGrid = () => import('../../modules/amplience/components/organisms/ArticleCardGrid.vue' /* webpackChunkName: "components/organism-article-card-grid" */).then(c => wrapFunctional(c.default || c))
export const OrganismMegaMenuDesktopMegaMenu = () => import('../../modules/amplience/components/organisms/mega-menu/DesktopMegaMenu.vue' /* webpackChunkName: "components/organism-mega-menu-desktop-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const OrganismMegaMenuTabletMobileMegaMenu = () => import('../../modules/amplience/components/organisms/mega-menu/TabletMobileMegaMenu.vue' /* webpackChunkName: "components/organism-mega-menu-tablet-mobile-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const SkeletonArticleCardGrid = () => import('../../modules/amplience/components/skeleton/ArticleCardGrid.vue' /* webpackChunkName: "components/skeleton-article-card-grid" */).then(c => wrapFunctional(c.default || c))
export const SkeletonArticleCardList = () => import('../../modules/amplience/components/skeleton/ArticleCardList.vue' /* webpackChunkName: "components/skeleton-article-card-list" */).then(c => wrapFunctional(c.default || c))
export const SkeletonFeatureBlock = () => import('../../modules/amplience/components/skeleton/FeatureBlock.vue' /* webpackChunkName: "components/skeleton-feature-block" */).then(c => wrapFunctional(c.default || c))
export const SkeletonHeroBanner = () => import('../../modules/amplience/components/skeleton/HeroBanner.vue' /* webpackChunkName: "components/skeleton-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const SkeletonJumboHeroBanner = () => import('../../modules/amplience/components/skeleton/JumboHeroBanner.vue' /* webpackChunkName: "components/skeleton-jumbo-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const SkeletonRichText = () => import('../../modules/amplience/components/skeleton/RichText.vue' /* webpackChunkName: "components/skeleton-rich-text" */).then(c => wrapFunctional(c.default || c))
export const SkeletonSubNavigation = () => import('../../modules/amplience/components/skeleton/SubNavigation.vue' /* webpackChunkName: "components/skeleton-sub-navigation" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
