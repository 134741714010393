import { Logger } from '~/helpers/logger';
import { UseContextReturn } from '~/types/core';
import { Context } from '@nuxt/types';
import { RoutableInterface } from '~/modules/GraphQL/types';
import { getPageType } from '~/modules/amplience/helpers/getPageType';

const clearUrl = (
  context: Context | UseContextReturn,
  path: string
) => (
  path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '')
);

// TODO: DG-5272 - See all PLPs showing the products per category
// This function checks if the path is an Algolia category
export const isAlgoliaCategory = (
  context: Context | UseContextReturn,
  path: string
) => {
  const isAlgoliaEnabled = context?.$algoliaSearch?.enabled || false;

  if (!isAlgoliaEnabled) {
    return false;
  }

  return [
    '/all-bbx-listings',
    '/new-to-bbx',
  ].includes(path);
};

export const magentoUrlResolver = async (
  context: Context | UseContextReturn,
  path: string
): Promise<RoutableInterface> => {
  try {
    const { data, errors } = await context.app.$vsf.$magento.api.route(
      clearUrl(context, path)
    );

    Logger.debug('middleware/url-resolver/magento-result', { data, errors });

    return data?.route ?? null;
  } catch (e) {
    Logger.info('middleware/url-resolver/magento-error', path, e);
  }

  return null;
};

export const amplienceUrlResolver = async (
  context: Context | UseContextReturn,
  path: string
): Promise<RoutableInterface> => {
  try {
    const url = clearUrl(context, path).replace(/^\/(.*)$/, '$1');

    const [content] = await context.app.$vsf.$ampl.api.getContent({
      url,
    });

    Logger.debug('middleware/url-resolver/amplience-result', { content });

    const schema = content?._meta?.schema || '';
    const pageType = getPageType(schema);

    if (!pageType) {
      // eslint-disable-next-line max-len
      Logger.error(`A page renderer for this schema ${schema} is not defined. Define it in config.ts:schemaPages in the root of the amplience module.`);
    }

    if (content && pageType && !content.isDraft) {
      return {
        type: pageType,
        identifier: url,
        redirect_code: 0,
      };
    }
  } catch (e) {
    Logger.error('middleware/url-resolver/amplience-error', path, e);
  }

  return null;
};
