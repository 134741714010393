import {
  computed,
  ref,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import {
  useUiNotification,
  useExternalCheckout,
  useImage,
  useProduct,
} from '~/composables';
import { useCart, UseCartErrors } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import type { UseCartViewInterface } from '~/modules/checkout/composables/useCartView/useCartView';
import type { ConfigurableCartItem, BundleCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import { useHybrisCart } from '~/modules/checkout/composables/useCart/useHybrisCart';
import { useHybrisCartStore } from '~/modules/checkout/stores/useHybrisCart';
import { storeToRefs } from 'pinia';
import { isDualRunning } from '~/bbrTheme/helpers/dualRunning';
import { useHybrisUser } from '~/modules/customer/composables/useUser/useHybrisUser';

/**
 * Allows loading and manipulating cart view.
 *
 * See the {@link UseCartViewInterface} for a list of methods and values available in this composable.
 */
export function useCartView(): UseCartViewInterface {
  const { localePath, app: { i18n, $cookies } } = useContext();
  const { initializeCheckout } = useExternalCheckout();
  const { getMagentoImage, imageSizes } = useImage();
  const router = useRouter();
  const { getProductPath } = useProduct();
  const {
    cart,
    removeItem,
    updateItemQty,
    load: loadCart,
    loading: loadingAdobe,
  } = useCart();

  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });

  const { loadCartAndUser, loading: loadingHybris } = useHybrisUser(error);

  const hybrisCartStore = useHybrisCartStore();

  const {
    cartId,
    totals: totalsHybris,
    totalItems: totalItemsHybris,
    productsByGroup: productsByGroupHybris,
  } = storeToRefs(hybrisCartStore);

  const cartLoading = computed(() => loadingAdobe.value || loadingHybris.value);

  const { isAuthenticated } = useUser();
  const { send: sendNotification, notifications } = useUiNotification();

  const productsByGroup = computed(() => isDualRunning($cookies)
    ? productsByGroupHybris.value : cartGetters
    .getGroupedItems(cart.value)
    .filter(Boolean)
    .map((group) => ({
      ...group,
      sort_order: cartGetters.getProductGroupSortOrder(group.group_name),
      items: group.items.map((item) => ({
        ...item,
        product: {
          ...item.product,
          ...[(item as ConfigurableCartItem).configured_variant ?? {}],
          original_sku: item.product.sku,
        },
      })),
    }))
    .sort((a, b) => a.sort_order - b.sort_order));

  const products = computed(() => cartGetters
    .getItems(cart.value)
    .filter(Boolean)
    .map((item) => ({
      ...item,
      product: {
        ...item.product,
        ...[(item as ConfigurableCartItem).configured_variant ?? {}],
        original_sku: item.product.sku,
      },
    })));
  // eslint-disable-next-line max-len
  const totals = computed(() => isDualRunning($cookies) ? totalsHybris.value : cartGetters.getTotals(cart.value));
  const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
  // eslint-disable-next-line max-len
  const totalItems = computed(() =>
    isDualRunning($cookies) ? totalItemsHybris.value : cartGetters.getTotalItems(cart.value)
  );
  const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
  const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
  const isRemoveModalVisible = ref(false);
  const itemToRemove = ref<CartItemInterface>();

  onMounted(() => {
    if (isDualRunning($cookies)) {
      if (!cartId.value) {
        loadCartAndUser();
      }
    } else {
      if (!cart.value?.id) {
        loadCart();
      }
    }
  });

  const goToCheckout = async () => {
    if (isDualRunning($cookies)) {
      window.location.replace('/checkout/multi/delivery');

      return;
    }

;

    const redirectUrl = initializeCheckout({ baseUrl: '/checkout/user-account' });
    await router.push(localePath(redirectUrl));
  };

  const showRemoveItemModal = ({ product }: { product: CartItemInterface }) => {
    if (notifications.value.length > 0) {
      notifications.value[0].dismiss();
    }

    isRemoveModalVisible.value = true;
    itemToRemove.value = product;
  };

  const removeItemAndSendNotification = async (product: CartItemInterface, hasNotification = false) => {
    await removeItem({ product });
    isRemoveModalVisible.value = false;

    if (hasNotification) {
      sendNotification({
        id: Symbol('product_removed'),
        message: i18n.t('{0} has been successfully removed from your cart', {
          0: cartGetters.getItemName(
            product,
          ),
        }) as string,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Product removed',
      });
    }
  };

  const delayedUpdateItemQty = debounce(
    (params) => updateItemQty(params),
    1000,
  );

  const isInStock = (product: CartItemInterface) => isDualRunning($cookies)
    // eslint-disable-next-line max-len
    ? product.postable ? true : product.retail ? product.hasRetailStock : product.hasInbondStock // always in stock for events and vouchers?
    : cartGetters.getStockStatus(product) === ProductStockStatus.InStock
  ;

  const selectedShippingMethod = computed(() => cartGetters.getSelectedShippingMethod(cart.value));

  const selectedShippingMethodPrice = computed(() => cartGetters.getShippingPrice(cart.value));

  return {
    showRemoveItemModal,
    removeItemAndSendNotification,
    delayedUpdateItemQty,
    goToCheckout,
    getAttributes,
    getBundles,
    isInStock,
    getMagentoImage,
    getProductPath,
    loading: cartLoading,
    isAuthenticated,
    products,
    productsByGroup,
    selectedShippingMethod,
    selectedShippingMethodPrice,
    isRemoveModalVisible,
    itemToRemove,
    totals,
    totalItems,
    cartId,
    imageSizes,
    discount,
    cartGetters,
  };
}

export default useCartView;
export * from './useCartView';
