export const customerBidsPlacedQuery = `
  query CustomerBids($currentPage: Int = 1, $pageSize: Int = 24, $filter: ProductAttributeFilterInput) {
    customer {
      bids(
        accountData: "default",
        currentPage: $currentPage,
        pageSize: $pageSize,
        filter: $filter
      ) {
        is_default
        sap_unique_id
        items {
          uid
          bid_id
          url_key
          name
          sku
          product_sku
          cases_qty
          highest_bid
          proposed_price
          bid_status
          listing_id
          lowest_listing_price
          description {
            html
          }
          url_key
          attributes_value(
            attribute_codes: [
              "main_image"
              "family_type"
              "colour"
              "maturity"
              "case_order_unit"
              "bottle_volume"
              "drinking_from_year"
              "drinking_to_year"
              "alcohol"
              "style_temp"
              "sweetness"
              "body"
              "grape_list"
            ]
          ) {
            code
            value
            label
            position
            sort_order
          }
          reviews {
            items {
              nickname
              score
            }
          }
        }
        aggregations(filter: {}) {
          attribute_code
          count
          label
          options {
            count
            label
            value
          }
          position
        }
        page_info {
          current_page
          page_size
          total_pages
        }
        sort_fields {
          default
          options {
            label
            value
          }
        }
        total_count
      }
    }
  }
`;
