









import { defineComponent } from '@nuxtjs/composition-api';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import ActionItem from '@theme/components/atoms/ActionItem.vue';

export default defineComponent({
  name: 'ActionItemCart',
  components: {
    ActionItem,
  },
  setup() {
    const { totalItems } = useCartView();

    return {
      totalItems,
    };
  },
});
