














import { defineComponent } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'ActionItemSearch',
  components: {
    SvgImage,
  },
});
