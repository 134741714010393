import { defineStore } from 'pinia';
import { LinksInterface } from '@cellar-services/types';

export interface AccountBreadcrumbsInterface {
  breadcrumbs: AccountBreadcrumb[];
}

export interface AccountBreadcrumb {
  text: string;
  link: string;
}

export const navigationLinks: LinksInterface[] = [{
  title: 'My account',
  items: {
    'my-account': {
      key: 'my-account',
      route: 'customer-my-account',
      label: 'Account',
    },
    'my-profile': {
      key: 'my-profile',
      route: 'customer-my-profile',
      label: 'Profile',
    },
    'my-address-book': {
      key: 'my-address-book',
      route: 'customer-my-address-book',
      label: 'Address book',
    },
    'manage-payments': {
      key: 'manage-payments',
      route: 'customer-manage-payments',
      label: 'Manage-payments',
    },
    'order-history': {
      key: 'order-history',
      route: 'customer-order-history',
      label: 'Orders, payments & statements',
    },
    'my-wishlist': {
      key: 'my-wishlist',
      route: 'customer-my-wishlist',
      label: 'Wishlist',
    },
    'my-interests': {
      key: 'my-interests',
      route: 'customer-my-interests',
      label: 'Interests',
    },
    'my-communication-preferences': {
      key: 'my-communication-preferences',
      route: 'customer-my-communication-preferences',
      label: 'Communication preferences',
    },
    'my-store-credit': {
      key: 'my-store-credit',
      route: 'customer-my-store-credit',
      label: 'Store credit',
    }
  }
}];

export const quickLinks = [
  {
    title: 'Profile',
    link: 'customer-my-profile',
    icon: 'account-user',
  },
  {
    title: 'Address book',
    link: 'customer-my-address-book',
    icon: 'account-book-open',
  },
  {
    title: 'Manage payments',
    link: 'customer-manage-payments',
    icon: 'account-credit-card',
  },
  {
    title: 'Orders, payments & statements',
    link: 'customer-order-history',
    icon: 'account-file',
  },
  {
    title: 'Wishlist',
    link: 'customer-my-wishlist',
    icon: 'account-heart',
  },
];

const quickAccessLinks = [
  {
    key: 'account',
    route: 'customer-my-account',
    label: 'My account',
  },
  {
    key: 'cellar',
    route: 'cellar',
    label: 'My cellar',
  },
  {
    key: 'bbx',
    route: 'bbx',
    label: 'My BBX',
  },
];

export const useMyAccountNavigation = defineStore('myAccountBreadcrumbs', {
  state: () => <AccountBreadcrumbsInterface>({
    breadcrumbs: [],
  }),
  getters: {
    // Remove cellar link only if data has loaded and is empty
    filteredQuickAccessLinks(): Function {
      return (isEmpty: boolean, hasLoaded: boolean, isDualRunningEnabled?: boolean) => {
        if (isDualRunningEnabled) {
          return quickAccessLinks;
        }

        return hasLoaded && isEmpty
          ? quickAccessLinks.filter(link => link.key !== 'cellar')
          : quickAccessLinks;
      }
    },
  },
  actions: {
    setBreadcrumbs(breadcrumbs: AccountBreadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },
  },
});
