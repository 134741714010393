import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';

export const useCellarUiStore = defineStore('cellar-ui', () => {
  // State
  const lastActiveItemId = ref<number | null>(null);
  const isOpenedFromHistory = ref<boolean>(false);

  // Actions
  const setLastActiveItemId = (value: number): void => { lastActiveItemId.value = value };

  const setIsOpenedFromHistory = (value: boolean): void => { isOpenedFromHistory.value = value };

  return {
    lastActiveItemId,
    isOpenedFromHistory,
    setLastActiveItemId,
    setIsOpenedFromHistory,
  };
});
