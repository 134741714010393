import { CategoryTree } from '~/modules/GraphQL/types';
import { Ref, ref, useContext } from '@nuxtjs/composition-api';
import useCategory from '~/modules/catalog/category/composables/useCategory';
import { LinkType } from '@theme/components/molecules/CtaPartial.vue';
import { Product } from '~/modules/catalog/product/types';
import { useProduct } from '~/composables';
import { useContent } from '@amplience/composables/useContent';

export interface useCtaPartialInterface {
  ctaUrl: Ref<string>;
  ctaExecRequest(linkType: string): Promise<void>;
}

export function useCtaPartial(linkType: string | null, id: string) : useCtaPartialInterface {
  const { categories, load } = useCategory();
  const categoryData = ref<CategoryTree | null>(null);
  const { localePath, app } = useContext();
  const { getProductDetails, getProductPath } = useProduct();
  const { search, data } = useContent();
  const prodUrl = app.$config.schemaUrl;
  const product = ref<Product | null>(null);
  const ctaUrl = ref<string>('');

  const ctaExecRequest = async (linkType) => {
    switch (linkType) {
      case LinkType.PRODUCT_SKU:
        const result = await getProductDetails({
          filter: {
            sku: {
              eq: id
            },
          },
        });

        product.value = result?.items?.[0] as Product ?? null;
        ctaUrl.value = localePath(getProductPath(product.value));

        break;

      case LinkType.CATEGORY_ID:
        await load({
          filters: {
            ids: {
              eq: id
            },
          },
        });

        categoryData.value = categories.value?.[0] || null;
        ctaUrl.value = categoryData.value?.url_path;

        break;

      case LinkType.AMPLIENCE_ID:
        await search(id, 'id');

        const schema = data.value?._meta?.schema;
        ctaUrl.value = schema.startsWith(prodUrl + '/slot') ? data.value?._meta?.deliveryKey : '';

        break;
    }
  }

  return {
    ctaUrl,
    ctaExecRequest
  }
}

export default useCtaPartial;
