import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import type { SummaryInterface } from '@cellar-services/types';
import { bbxStore } from './bbx/bbx-store';

export const useCellarStore = defineStore('cellar', () => {
  // exporting from common store
  const {
    items,
    aggregations,
    isLoading,
    isBlockingLoading,
    hasLoaded,
    filters,
    isEmpty,
    areAggregationsEmpty,
    graphQueryParams,
    uniqueID,
    defaultID,
    linkedAccounts,
    setItems,
    setItem,
    setAggregations,
    setIsBlockingLoading,
    setIsLoading,
    pageSize,
    totalItems,
    totalPages,
    currentPage,
    itemsRange,
    setCurrentPage,
    setPageSize,
    setTotalItems,
    setTotalPages,
    clearFilters,
    setFilter,
    resetPagination,
    activeRows,
    resetActiveRows,
    toggleRow,
    closeRow,
    shouldTableSkeletonRender,
    paginationInfo,
    queryParams,
    resetStore,
  } = bbxStore();

  // State
  const stats = ref<SummaryInterface>(null);

  // Actions
  const setStats = (value) => (stats.value = value);

  return {
    items,
    aggregations,
    isLoading,
    isBlockingLoading,
    hasLoaded,
    filters,
    isEmpty,
    areAggregationsEmpty,
    queryParams,
    graphQueryParams,
    uniqueID,
    defaultID,
    linkedAccounts,
    setStats,
    setItems,
    setItem,
    setAggregations,
    setIsBlockingLoading,
    setIsLoading,
    pageSize,
    totalItems,
    totalPages,
    currentPage,
    itemsRange,
    setCurrentPage,
    setPageSize,
    setTotalItems,
    setTotalPages,
    clearFilters,
    setFilter,
    resetPagination,
    stats,
    activeRows,
    shouldTableSkeletonRender,
    paginationInfo,
    resetActiveRows,
    toggleRow,
    closeRow,
    resetStore,
  };
});
