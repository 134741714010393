import { ref } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import {
  BBRProductAttributes,
  getAttributeValue,
} from '@theme/modules/catalog/getters/productGetters';
import { AddToCartNotificationInterface, ProductAttributeInterface } from '@theme/modules/catalog/types';
import { useUiState } from '@theme/modules/catalog/composables/useUiState';
import { SimpleProduct } from '~/modules/GraphQL/types';

export function useAddToCartNotification() {
  const addedProduct = ref<AddToCartNotificationInterface | null>(null);
  const { isAddToCartNotification, toggleAddToCartNotification } = useUiState();

  const setAddedProduct = (notification: AddToCartNotificationInterface | null) => {
    addedProduct.value = notification;
  };

  const resetAddedProduct = () => {
    setAddedProduct(null);
  };

  const displayAddToCartNotification = ({
    isAdded,
    isCase,
    product,
    variant,
    quantity,
  }: {
    isAdded: boolean;
    isCase: boolean;
    product: Product,
    variant: SimpleProduct | null,
    quantity: number,
  }) => {
    if (isAdded) {
      resetAddedProduct();
      const attributes = [] as ProductAttributeInterface[];

      // Add bottle volume and bottle order unit to attributes
      if (variant) {
        const bottleVolume = getAttributeValue(variant, BBRProductAttributes.BOTTLE_VOLUME);
        const bottleOrderUnit = getAttributeValue(variant, BBRProductAttributes.BOTTLE_ORDER_UNIT);

        if (bottleVolume && bottleOrderUnit) {
          attributes.push({
            label: bottleOrderUnit,
            value: bottleVolume,
          });
        }
      }

      // Set notification data
      setAddedProduct({
        product: product,
        variant: variant || null,
        qty: quantity || 1,
        isCase: !!(isCase) || false,
        attributes,
      });

      // Show notification sidebar
      toggleAddToCartNotification();
    }
  }

  return {
    addedProduct,
    isAddToCartNotification,
    toggleAddToCartNotification,
    setAddedProduct,
    resetAddedProduct,
    displayAddToCartNotification,
  }
}

export default useAddToCartNotification;
