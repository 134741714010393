import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useHybrisCart } from '~/modules/checkout/composables/useCart/useHybrisCart';

export const useHybrisUser = (error) => {
  const loading = ref(false);

  const { app } = useContext();
  const customerStore = useCustomerStore();
  const { load: loadHybrisCart } = useHybrisCart(loading, error);

  /**
   * Loads Hybris cart.
   * If cart is not anonymous, loads user profile too.
   * Sets both responses to its stores.
   */
  const loadCartAndUser = async () => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.loadCartAndUser');

        const response = await loadHybrisCart();

        Logger.debug('[Hybris] cart:', { response });

        if (response.cartData.user.uid === 'anonymous') {
          return;
        }

        const { data } = await app.context.$vsf.$hybris.api.getAccountProfile();

        Logger.debug('[Hybris] profile:', { data });

        customerStore.user = {
          firstname: data.customer.firstName,
          lastname: data.customer.lastName,
          algolia_user_public_key: data.algolia_user_public_key || '',
          email: data.customer.uid,
          prefix: data.customer.title,
          sap_unique_id: data.customer.bvUid,
          sap_default_id: data.customer.bvUid,
          linked_accounts: [data.customer.bvUid], //TODO
          bbx_buyer: '1', //TODO
          bbx_seller: '1', //TODO
          wishlist: {},
          wishlists: [],
          reviews: { items: [], page_info: {} },
          custom_attributes: {},
          allow_remote_shopping_assistance: false,
          // TODO: This needs to be pulled in from Hybris.
          commission_rate: '15',
        };
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.loadCartAndUser', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/loadCartAndUser', err);
    } finally {
      loading.value = false;
    }
  };

  /**
   * Loads Hybris user profile.
   */
  const loadUser = async () => {
    try {
      loading.value = true;

      try {
        Logger.debug('[Hybris] useHybrisUser.loadCartAndUser');

        const { data } = await app.context.$vsf.$hybris.api.getAccountProfile();

        Logger.debug('[Hybris] profile:', { data });

        customerStore.user = {
          firstname: data.customer.firstName,
          lastname: data.customer.lastName,
          algolia_user_public_key: data.customer.algolia_user_public_key,
          email: data.customer.uid,
          prefix: data.customer.title,
          sap_unique_id: data.customer.bvUid,
          sap_default_id: data.customer.bvUid,
          linked_accounts: [data.customer.bvUid], //TODO
          bbx_buyer: '1', //TODO
          bbx_seller: '1', //TODO
          wishlist: {},
          wishlists: [],
          reviews: { items: [], page_info: {} },
          custom_attributes: {},
          allow_remote_shopping_assistance: false,
          // TODO: This needs to be pulled in from Hybris.
          commission_rate: '15',
        };
      } catch (err) {
        Logger.error('[Hybris] useHybrisUser.load', err);
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useHybrisUser/load', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    loadCartAndUser,
    loadUser,
    loading,
  };
};
