import { ref, useContext, useRouter, onMounted } from '@nuxtjs/composition-api';
import { useApi, useUiNotification } from '~/composables';
import { UseAuthInterface } from './useAuth';
import { AuthenticationResult } from '@azure/msal-common';
import { GenerateCustomerTokenQuery } from '~/modules/azure-b2c-auth/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCellar } from '@cellar-services/composables/useCellar';
import { useBBXListings, useBBXBidsReceived, useBBXBidsPlaced } from '@cellar-services/composables/useBBX';
import GENERATE_AZURE_TOKEN from '~/modules/azure-b2c-auth/queries/generateCustomerToken.gql';
import { RedirectRequestParams } from './types';
import { isDualRunning } from '~/bbrTheme/helpers/dualRunning';

export const useAuth = (): UseAuthInterface => {
  const accountId = ref<any>(null);
  const isAuthCustomer = ref<boolean>(false);
  const { mutate } = useApi();
  const context = useContext();
  const { $msal, $msalConfig, app, localeRoute } = context;
  const { logout: logoutCustomer, load: loadCustomer } = useUser();
  const { refreshCellar } = useCellar();
  const { getBBXListings } = useBBXListings();
  const { getBidsReceived } = useBBXBidsReceived();
  const { getBidsPlaced } = useBBXBidsPlaced();
  const { clear: clearCart, setCart } = useCart();
  const { send: sendNotification } = useUiNotification();
  const customerStore = useCustomerStore();
  const router = useRouter();
  const error = ref<any>(null);

  const handleRedirect = async (): Promise<AuthenticationResult | null> => {
    let result = null;

    try {
      result = await $msal.handleRedirectPromise();
    } catch (error) {
      error.value = error;
    }

    return result;
  };

  const create = async (): Promise<void> => {
    try {
      await $msal.loginRedirect({
        authority: $msalConfig.signUpPolicy,
        scopes: $msalConfig.scopes,
      });
    } catch (error) {
      error.value = error;
    }
  };

  const login = async (params?: RedirectRequestParams): Promise<void> => {
    try {
      await $msal.loginRedirect({
        authority: $msalConfig.signInPolicy,
        scopes: $msalConfig.scopes,
        ...(params ?? {}),
      });
    } catch (error) {
      error.value = error;
    }
  };

  const logout = async (params?: { redirectUrl?: string }): Promise<void> => {
    try {
      await logoutCustomer({});
      await clearCart({});

      if (isDualRunning(app.$cookies)) {
        context.redirect('/');
      } else {
        await $msal.logoutRedirect({
          account: await $msal.getAccountByHomeId(accountId.value),
          authority: $msalConfig.signInPolicy,
          postLogoutRedirectUri: params?.redirectUrl ? params.redirectUrl : $msalConfig.logoutRedirectUri,
        });
      }
    } catch (error) {
      error.value = error;
    }
  };

  const authCustomer = async (token: string): Promise<void> => {
    const apiState = app.context.$vsf.$magento.config.state;

    console.log('[setIsLoggedIn] Azure B2C customer login.');
    customerStore.setIsLoggedIn(true);
    apiState.setCustomerToken(token);

    isAuthCustomer.value = true;
  };

  const mergeCarts = async (): Promise<void> => {
    const apiState = app.context.$vsf.$magento.config.state;
    const currentCartId = apiState.getCartId();
    const cart = await app.context.$vsf.$magento.api.customerCart();
    const newCartId = cart.data.customerCart.id;

    if (newCartId && currentCartId && currentCartId !== newCartId) {
      const { data } = await app.context.$vsf.$magento.api.mergeCarts(
        {
          mergeCarts: 'mergeCarts',
          sourceCartId: currentCartId,
          destinationCartId: newCartId,
        },
      );

      setCart(data.mergeCarts);
      apiState.setCartId(data.mergeCarts.id);
    } else {
      setCart(cart.data.customerCart);
    }
  };

  onMounted(async () => {
    const response = await handleRedirect();
    const token = app.$vsf.$magento.config.state.getCustomerToken();

    if (response?.account && !token) {
      const { data, errors } = await mutate<GenerateCustomerTokenQuery>(
        GENERATE_AZURE_TOKEN, {
          code: response.idToken
      });

      if (errors?.length) {
        errors.forEach((error, i) => sendNotification({
          icon: 'error',
          id: Symbol(`azure_token_error-${i}`),
          message: error.message,
          persist: true,
          title: 'Azure authentication error',
          type: 'danger',
        }));
      } else {
        await authCustomer(data.generatedToken.token);
        await mergeCarts();
      }
    }

    const customer = await loadCustomer();

    // TODO: Figure out a better place to call this to improve UX and performance
    try {
      if (customer?.email) {
        // Load user's cellar after login
        refreshCellar();

        // Load user's BBX listings and bids received after login
        getBBXListings();
        getBidsReceived();
        getBidsPlaced();
      }
    } catch {
      // TODO: Handle errors
      console.error('Error when fetching cellar and BBX data');
    }
  });

  return {
    error,
    isAuthCustomer,
    create,
    login,
    logout,
  };
};
