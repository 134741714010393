import { useContext } from '@nuxtjs/composition-api';

/**
 * BBX account actions
 */
export const useBBXAccount = () => {
  const context = useContext();

  const registerAsSeller = async (payload): Promise<any> =>
    await context.$vsf.$cellarServices.api.registerSeller(payload);

  const registerAsBidder = async (payload): Promise<any> =>
    await context.$vsf.$cellarServices.api.registerBidder(payload);

  return {
    registerAsSeller,
    registerAsBidder,
  };
};
